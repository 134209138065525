import React from "react";
import styles from "./WaveSpinner.module.css";

const WaveSpinner = () => {
  return (
    <div>
      {/* Wave Loader starts */}
      {/* <div className={styles.water}></div> */}
      {/* Wave Loader Ends */}

      {/* Box Loader satrts */}
      <div className={styles.boxes}>
        <div className={styles.box}>
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className={styles.box}>
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className={styles.box}>
          <div />
          <div />
          <div />
          <div />
        </div>
        <div className={styles.box}>
          <div />
          <div />
          <div />
          <div />
        </div>
      </div>
      {/* Box Loading Ends */}

      {/* Planet Loader Starts */}
      {/* <div className={styles.content}>
        <div className={styles.planet}>
          <div className={styles.ring} />
          <div className={styles.coverRing} />
          <div className={styles.spots}>
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
            <span />
          </div>
        </div>
        <p>loading</p>
      </div> */}
      {/* Planet Loader Ends */}
    </div>
  );
};

export default WaveSpinner;
