import axios from "axios";

//creating Axios instance

export default axios.create({
  baseURL: process.env.REACT_APP_KART_BASE_URL,

  headers: {
    "content-type": "application/json",
  },
});
