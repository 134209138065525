import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../apis/Axios";
import { toast } from "react-toastify";

const initialState = {
  addressList: [],
  orderAddress: {},
  error: "",
};

export const fetchAddress = createAsyncThunk(
  "address/fetchAddress",
  async payload => {
    let { userId, authToken } = payload;
    return Axios.get(`/shoppers/${userId}/address`, authToken);
  }
);

export const deleteAddress = createAsyncThunk(
  "address/deleteAddress",
  async obj => {
    try {
      let { userId, addressId, authToken } = obj;
      await Axios.delete(`shoppers/${userId}/address/${addressId}`, authToken);
      alert("Address deleted successfully");
      return { addressId };
    } catch (err) {
      toast.error(err.message);
    }
  }
);

export const editAddress = createAsyncThunk(
  "address/editAddress",
  async obj1 => {
    let { userId, addressId, currPayload, authToken } = obj1;
    await Axios.put(
      `shoppers/${userId}/address/${addressId}`,
      currPayload,
      authToken
    );
    return { addressId, currPayload };
  }
);

const addressSlice = createSlice({
  name: "address",
  initialState,
  reducers: {
    addOrderAddress: (state, action) => {
      state.orderAddress = action.payload;
    },
  },
  extraReducers: {
    [fetchAddress.fulfilled]: (state, action) => {
      state.addressList = action.payload.data.data;
    },
    [fetchAddress.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [deleteAddress.fulfilled]: (state, action) => {
      let index = state.addressList.findIndex(
        ({ addressId }) => addressId == action.payload.addressId
      );
      state.addressList.splice(index, 1);
    },
    [deleteAddress.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [editAddress.fulfilled]: (state, action) => {
      let index = state.addressList.findIndex(
        v => v.addressId == action.payload.addressId
      );
      state.addressList.splice(index, 1, action.payload.currPayload);
    },
    [editAddress.rejected]: (state, action) => {
      state.error = action.error.message;

      console.log(action.payload.data.message);
    },
    [deleteAddress.rejected]: (state, action) => {
      console.log(action.payload.data.message);
    },
  },
});

export default addressSlice.reducer;
export const { addOrderAddress } = addressSlice.actions;
