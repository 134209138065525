import { createRoot } from "react-dom/client";
import React, { Suspense, lazy } from "react";
import "./global.css";
import store from "./app/store";
import { Provider } from "react-redux";

import BackdropSpinner from "./components/spinner/BackdropSpinner";
const App = lazy(() => import("./App"));

const root = document.getElementById("root");

createRoot(root).render(
  <Provider store={store}>
    <Suspense fallback={<BackdropSpinner open={true} />}>
      <App />
    </Suspense>
  </Provider>
);
