import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../apis/Axios";

const initialState = {
  wishList: [],
  error: "",
};

export const addToWishlist = createAsyncThunk(
  "wishlist/addToWishlist",
  async data => {
    let { userId, payload, authToken } = data;
    await Axios.post(`/shoppers/${userId}/wishlist`, payload, authToken);
    return { payload };
  }
);

export const getAllWishlist = createAsyncThunk(
  "wishlist/getAllWishlist",
  async data => {
    let { userId, authToken } = data;
    return Axios.get(`/shoppers/${userId}/wishlist`, authToken);
  }
);

export const deleteFromWishlist = createAsyncThunk(
  "wishlist/deleteFromWishlist",
  async ({ userId, productId, authToken }) => {
    await Axios.delete(`/shoppers/${userId}/wishlist/${productId}`, authToken);
    return { productId };
  }
);

const wishlistSlice = createSlice({
  name: "wishlist",
  initialState,
  extraReducers: builder => {
    builder.addCase(addToWishlist.fulfilled, (state, action) => {
      state.wishList.push(action.payload.payload);
    });
    builder.addCase(addToWishlist.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(deleteFromWishlist.fulfilled, (state, action) => {
      let index = state.wishList.findIndex(
        ({ productId }) => productId == action.payload.wishlistId
      );
      state.wishList.splice(index, 1);
    });
    builder.addCase(deleteFromWishlist.rejected, (state, action) => {
      state.error = action.error.message;
    });
    builder.addCase(getAllWishlist.fulfilled, (state, action) => {
      state.wishList = action.payload.data.data;
    });
    builder.addCase(getAllWishlist.rejected, (state, action) => {
      state.error = action.error.message;
    });
  },
});

export default wishlistSlice.reducer;
