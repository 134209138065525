import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../apis/Axios";

const initialState = {
  balance: 0,
};

export const getWallet = createAsyncThunk("wallet/getWallet", payload => {
  let { userId, authToken } = payload;
  return Axios.get(`/shoppers/${userId}/wallets/latest`, authToken);
});

const walletSlice = createSlice({
  name: "wallet",
  initialState,
  extraReducers: builder => {
    builder.addCase(getWallet.fulfilled, (state, action) => {
      state.balance = action.payload.data.data?.balance;
    });
  },
});

export default walletSlice.reducer;
