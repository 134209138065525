import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import PaymentAxios from "../../apis/PaymentAxios";

const initialState = {
  customer: null,
};

export const getCustomer = createAsyncThunk(
  "bankSlice/getCustomer",
  ({ bankId, email }) => {
    return PaymentAxios.get(`/banks/${bankId}/customers/email/${email}`);
  }
);

const bankSlice = createSlice({
  name: "bank",
  initialState,
  extraReducers: builder => {
    builder.addCase(getCustomer.fulfilled, (state, action) => {
      state.customer = action.payload.data.data;
    });
  },
});

export default bankSlice.reducer;
