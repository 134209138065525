import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import Axios from "../../apis/Axios";
import { toast } from "react-toastify";
import Cookie from "js-cookie";
const initialState = {
  currentUser: {},
  likes: null,
  token: null,
  error: "",
};

// editProfile
export const editProfile = createAsyncThunk(
  "user/editProfile",
  async ({ userData, authToken }) => {
    let { id, payload } = userData;
    try {
      let { data } = await Axios.put(`shoppers/${id}`, payload, authToken);
      toast.success(data.message);
      return { ...userData };
    } catch (error) {
      toast.error(error.response.data.data);
    }
  }
);
export const getCurrentUser = createAsyncThunk(
  "user/getCurrentUser",
  async payload => {
    let { userId, authToken } = payload;
    let { data } = await Axios.get(`/shoppers/${userId}`, authToken);
    return data;
  }
);

// ADMIN EDIT PROFILE
export const editAdminProfile = createAsyncThunk(
  "user/editAdminProfile",
  async ({ userData, authToken }) => {
    let { id, payload } = userData;
    try {
      let { data } = await Axios.put(`admin/${id}`, payload, authToken);
      toast.success(data.message);
      return { ...userData };
    } catch (error) {
      toast.error(error.response.data.data);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    createCurrentUser: (state, action) => {
      let { refreshToken, currentUser } = action.payload;
      localStorage.setItem("user", JSON.stringify(currentUser));
      state.currentUser = { ...currentUser };
      state.token = refreshToken;
    },
    logoutCurrentUser: (state, action) => {
      localStorage.removeItem("user");
      localStorage.removeItem("likes");
      localStorage.removeItem("products");
      localStorage.removeItem("imageId");
      Cookie.remove("login");
      state.currentUser = {};
      state.token = null;

      if (window.location.pathname != "" || window.location.pathname != "/") {
        window.location.assign("/");
      }
    },
  },
  extraReducers: {
    [editProfile.fulfilled]: (state, action) => {
      // state.currentUser = { ...action.payload?.payload };
    },
    [editProfile.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [editAdminProfile.fulfilled]: (state, action) => {
      state.currentUser = { ...action.payload?.payload };
    },
    [editAdminProfile.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    [getCurrentUser.fulfilled]: (state, action) => {
      state.currentUser = action.payload.data;
      state.likes = action.payload.data.likes;
    },
    [getCurrentUser.rejected]: (state, action) => {
      state.error = action.error.message;
    },
  },
});

export default userSlice.reducer;
export let { createCurrentUser, logoutCurrentUser } = userSlice.actions;
