import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../apis/Axios";
import { toast } from "react-toastify";

const initialState = {
  cartItems: [],
  error: "",
  filteredCart: [],
  cartTotal: "",
  totalDiscount: "",
};

export const addToCart = createAsyncThunk("cart/addToCart", data => {
  let { payload, authToken } = data;
  return Axios.post(`/shoppers/${data.userId}/carts`, payload, authToken);
  // return { payload };
});

export const getCart = createAsyncThunk("cart/getCart", data => {
  let { userId, authToken } = data;
  return Axios.get(`/shoppers/${userId}/carts`, authToken);
});
export const deleteFromCart = createAsyncThunk(
  "cart/deleteFromCart",
  payload => {
    let { userId, productId, authToken } = payload;
    return Axios.delete(`/shoppers/${userId}/carts/${productId}`, authToken);
    
  }
);
export const updateCart = createAsyncThunk("cart/updateCart", payload => {
  Axios.put(
    `/shoppers/${payload.userId}/carts/${payload.itemid}`,
    payload.data
  );
  return { payload };
});

const cartSlice = createSlice({
  name: "cart",
  initialState,
  reducers: {
    getCartTotal: (state, action) => {
      state.cartTotal = state.filteredCart?.reduce(
        (acc, item) => acc + item.price * item.quantity,
        0
      );
    },
    getTotalDiscount: (state, action) => {
      state.totalDiscount = state.filteredCart?.reduce((acc, item) => {
        let discount = Math.trunc(item.price * (item?.offer / 100));
        let discountedPrice = item.price - discount;
        return acc + discountedPrice * item.quantity;
      }, 0);
    },
    addFilteredCart: (state, action) => {
      state.filteredCart = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(addToCart.fulfilled, (state, action) => {
      state.cartItems?.push(action.payload.data.data);
    });
    builder.addCase(addToCart.rejected, (state, action) => {
      console.log(action.error);
      toast.error(action.error.message);
    });
    builder.addCase(getCart.fulfilled, (state, action) => {
      state.cartItems = action.payload.data.data;
    });
    // builder.addCase(getCart.rejected, (state, action) => {
    //   state.error = action.payload.data;
    // });

    // builder.addCase(updateCart.fulfilled, (state, action) => {
    //   let index = state.cartItems.findIndex(
    //     v => v.itemId == action.payload.payload.itemid
    //   );
    //   state.addressList.splice(index, 1, action.payload.payload.data);
    // });

    builder.addCase(deleteFromCart.fulfilled, (state, action) => {
       state.cartItems=action.payload.data.data
    });
    builder.addCase(deleteFromCart.rejected, (state, action) => {
      state.error = action.payload.data;
    });
  },
});

export default cartSlice.reducer;
export const { getCartTotal, getCartCount, getTotalDiscount, addFilteredCart } =
  cartSlice.actions;
