import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../apis/Axios";

const initialState = {
  merchantsList: [],
  currentMerchant: [],
  productList:[],
  error: "",
};

//TODO  :: TO GET ONE MERCHANT PRODUCT LIST

export const fetchMerchantProducts = createAsyncThunk("products/fetchMerchantProducts", (merchId)=>{
  return Axios.get(`/products/merchant/${merchId}`)
})



// TODO  :: Get all Merchants
export const fetchMerchants = createAsyncThunk("merchants/fetchMerchants", () => {
  return Axios.get(`/merchants`);
});


// TODO :: GET ONE MERCHANT
export const getCurrentMerchant = createAsyncThunk(
  "merchants/getCurrentMerchant",
  ({merchantId , authToken}) => {
    return Axios.get(`/merchants/${merchantId}`, authToken);
  }
);



const merchantSlice = createSlice({
  name: "merchants",
  initialState,
  extraReducers: {
    // ** TO GET ALL merchants 
    [fetchMerchants.fulfilled]: (state, action) => {
      state.merchantsList = action.payload.data.data;
    },
    [fetchMerchants.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    // ** TO GET ONE MERCHANT
    [getCurrentMerchant.fulfilled]: (state, action) => {
      state.currentMerchant = action.payload.data.data;
    },
    [getCurrentMerchant.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    // ** TO GET ONE MERCHANT PRODUCT
    [fetchMerchantProducts.fulfilled]: (state, action) => {
      state.productList = action.payload.data.data;
    },
    [fetchMerchantProducts.rejected]: (state,action)=>{
      state.error = action.error.message;
    }
  },
});
export default merchantSlice.reducer;

