import * as React from "react";
import Backdrop from "@mui/material/Backdrop";
import { Typography } from "@mui/material";
import WaveSpinner from "./WaveSpinner";

export default function BackdropSpinner({ open, text }) {
  return (
    <div onClick={e => e.stopPropagation()}>
      <Backdrop
        sx={{
          color: "#666",
          zIndex: 99,
          backgroundColor: "#ffffff40",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "center",
          gap: "10px",
        }}
        open={open}
      >
        <WaveSpinner />
        <Typography sx={{ color: "#fbb800" }}>{text}</Typography>
      </Backdrop>
    </div>
  );
}
