import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import Axios from "../../apis/Axios";

const initialState = {
  productList: [],
  currentProduct: [],
  searchedProducts: [],
  allSearchTags: [],
  error: "",
};

//TODO BEGIN :: MERCHANT MODULE FUNCTIONS

export const fetchMerchantProducts = createAsyncThunk(
  "products/fetchMerchantProducts",
  merchId => {
    return Axios.get(`/products/merchant/${merchId}`);
  }
);

//TODO END :: MERCHANT MODULE FUNCTIONS

//BEGIN :: USER MODULE FUNCTIONS

export const fetchProducts = createAsyncThunk(
  "product/fetchProducts",
  ({ zoneId, authToken }) => {
    if (zoneId && authToken) {
      return Axios.get(`/products?zoneId=${zoneId}`, authToken);
    } else {
      return Axios.get(`/products/alpha`);
    }
  }
);
export const getCurrentProduct = createAsyncThunk(
  "product/getCurrentProduct",
  payload => {
    let { id, authToken } = payload;
    return Axios.get(`/products/${id}`, authToken);
  }
);

//END :: USER MODULE FUNCTIONS

const productSlice = createSlice({
  name: "product",
  initialState,
  reducers: {
    searchProducts: (state, action) => {
      state.searchedProducts = action.payload;
    },
  },
  extraReducers: {
    // * BEGIN :: CUSTOMER MODULE REDUCERS
    [fetchProducts.fulfilled]: (state, action) => {
      state.productList = action.payload.data.data;
      localStorage.setItem(
        "products",
        JSON.stringify(action.payload.data.data)
      );
      // console.log(state.productList)
    },
    [getCurrentProduct.fulfilled]: (state, action) => {
      state.currentProduct = action.payload.data.data;
    },
    [fetchProducts.rejected]: (state, action) => {
      console.log(action);
      state.error = action.error.message;
      if (action.error.message === "Request failed with status code 403") {
        localStorage.removeItem("user");
        localStorage.removeItem("likes");
        localStorage.removeItem("products");
        window.location.assign("/");
      }
    },
    [getCurrentProduct.rejected]: (state, action) => {
      state.error = action.error.message;
    },
    // * END :: CUSTOMER MODULE REDUCERS

    // * BEGIN :: MERCHANT MODULE REDUCERS
    [fetchMerchantProducts.fulfilled]: (state, action) => {
      state.productList = action.payload.data.data;
    },
    [fetchMerchantProducts.rejected]: (state, action) => {
      state.error = action.error.message;
      if (action.error.message === "Request failed with status code 403") {
        localStorage.removeItem("user");
        localStorage.removeItem("likes");
        localStorage.removeItem("products");
        window.location.assign("/");
      }
    },

    // * END :: CUSTOMER MODULE REDUCERS
  },
});
export default productSlice.reducer;
export const { searchProducts } = productSlice.actions;
